import React from "react"
import Layout from "../components/Layout"
import { SiReact } from "react-icons/si"
import { SiGatsby } from "react-icons/si"
import { SiTailwindcss } from "react-icons/si"

const About = () => {
  return (
    <Layout>
      <main id="main" style={{ backgroundColor: "rgb(36, 41, 67)" }}>
        <h2 className="text-center text-4xl font-bold my-16">
          About Bridgetown Digital Media
        </h2>
        <div className="my-16">
          <article className="mx-16">
            <p className="text-xl">
              Hi! My name is Mark Funkhouser, I'm a full stack developer with a
              passion for creating useful web applications in the non-profit /
              social-justice / community building ecosystem.
            </p>
            <p className="text-xl">
              I believe technology has a crucial role to play in making the
              world a better place for everyone, so I started Bridgetown Digital
              Media in 2019 to do just that!
            </p>
            <p className="my-5 text-2xl font-bold">
              This website was built using:
            </p>
            <ul className="flex items-center align-center">
              <li className="flex flex-col items-center align-center mr-16">
                <p className="mb-2">React</p>
                <SiReact className="text-5xl" style={{ color: "#61dbfb" }} />
              </li>
              <li className="flex flex-col items-center align-center mr-16">
                <p className="mb-2">Gatsby</p>
                <SiGatsby className="text-5xl" style={{ color: "#663399" }} />
              </li>
              <li className="flex flex-col items-center align-center ">
                <p className="mb-2">Tailwind</p>
                <SiTailwindcss
                  className="text-5xl"
                  style={{ color: "#11ccb6" }}
                />
              </li>
            </ul>
          </article>
        </div>

        <div className="grid grid-cols-3 grid-auto-rows px-16 md:px-32 lg:px-64"></div>
      </main>
    </Layout>
  )
}

export default About
